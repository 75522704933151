import * as React from "react";
import Link, { type LinkProps } from "next/link";
import type { WithChildrenAndClassName } from "../types/react";

// `as` is used for polymorpic components
export type UnstyledLinkProps = WithChildrenAndClassName<
	Omit<LinkProps, "as"> & { linkAs?: LinkProps["as"]; external?: boolean }
>;

export const UnstyledLink = React.forwardRef<
	HTMLAnchorElement,
	UnstyledLinkProps
>(({ children, className, external, ...props }, ref) => (
	<Link
		{...props}
		className={className}
		target={external ? "_blank" : "_self"}
		rel={external ? "noreferrer" : ""}
		ref={ref}
	>
		{children}
	</Link>
));

UnstyledLink.displayName = "UnstyledLink";
