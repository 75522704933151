import * as React from "react";
import Head from "next/head";
import Heading from "../components/heading";
import Layout from "../components/Layout";
import { getSiteSettings } from "../routes/common";
import { AnchorButton } from "../components/anchor-button";
import sx from "./404.module.css";
import { SeoMeta } from "@/types/generated/schema";

const FourOFourPage = ({ config }) => {
	const seoTags: SeoMeta = {
		_type: "seoMeta",
		title: "Pagina niet gevonden",
	};

	return (
		<Layout config={config} tags={seoTags}>
			<Head>
				<meta name="robots" content="noindex" />
			</Head>
			<div className="grid gap-9 grid-cols-1 items-center justify-center m-auto px-8 py-36 max-w-6xl min-h-hero md:grid-cols-2 md:py-32">
				<div className="flex flex-col col-span-1 items-center">
					<AnchorButton href="/" variant="dark">
						Terug naar home
					</AnchorButton>
					<Heading
						as="h1"
						className="mt-4 text-center text-5xl md:text-6xl xl:text-8xl"
					>
						Pagina niet gevonden
					</Heading>
				</div>
				<div className="flex col-span-1 justify-center">
					<iframe
						title="Drommels drommels..."
						src="https://giphy.com/embed/MdB1001RtVqsEGMXOH"
						width="392"
						height="480"
						frameBorder="0"
						className={sx.giphyEmbed}
						loading="lazy"
					></iframe>
				</div>
			</div>
		</Layout>
	);
};

export default FourOFourPage;

export const getStaticProps = async (context) => {
	const config = await getSiteSettings();

	return {
		props: {
			config,
		},
		revalidate: 60,
	};
};
